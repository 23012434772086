<!-- eslint-disable -->
<template>
  <section
    class="part newsletter-container"
    :class="{ 'bg-blue': isBlue, 'bg-white': !isBlue }"
  >
    <clip
      class="clipper"
      :clip-top="payload.clip"
      :clip-top-mobile="payload.clipmobile"
    />
    <section v-if="payload.rassenbeschreib.length > 0" v-view class="absolute rassen-circle animate in-bottom">
      <circle-link :blue="true" :to="'https://admin.ginevracobberdog.jkweb.dev/download/3/Rassestandard_Australian_Cobberdogs.pdf?inline=true'">
        <div class="flex justify-center flex-col items-center h-full text-center text-m">
          <div class="text-gray">
            Zum offiziellen Rassenbeschrieb
          </div>
          <arrow class="arrow" />
        </div>
      </circle-link>
    </section>
    <div v-view class="constrain relative animate in-bottom">
      <div class="row-12">
        <div class="col-12 md:col-6 md:offset-3">
          <div class="text-active title">
            {{ payload.texts.title }}
          </div>
          <h2 class="text-gray tease">
            {{ payload.texts.tease }}
          </h2>

          <form action="https://ginevracobberdog.us5.list-manage.com/subscribe/post?u=ee758bdc6907623fd4f8c0a85&amp;id=3627389d1c" method="post" target="_blank">
            <div class="input-wrapper mb-8">
              <input
                v-model="email"
                class="email-input"
                name="EMAIL"
                type="email"
                required
                placeholder="Deine Email-Adresse"
              >
            </div>
            <div>
              <input
                type="submit"
                class="btn btn-anmelden"
                :value="payload.texts.optIn"
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, ref } from 'vue';
import CircleLink from '../utils/CircleLink.vue';
import Arrow from '@/assets/images/arrow-right.svg';

export default {
  components: { CircleLink, Arrow },
  props: { payload: { type: Object, default: () => ({}) } },
  setup(props) {
    const email = ref();
    const subscribeNewsletter = (e) => {
      e.preventDefault();
      console.log(email.value);
    };

    return {
      email,
      isBlue: computed(() => props.payload.hintergrund.includes('blau')),
      subscribeNewsletter,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.title {
  @apply text-m;

  line-height: px(32);
  font-weight: 800;
  @screen md {
    @apply text-l;
  }
  @include responsive('line-height', px(24), px(32));
}

.newsletter-container {
  @apply pb-28 flex flex-col justify-end;

  z-index: 10;
  @include responsive('min-height', px(450), px(600));
}

.email-input {
  background-color: transparent;
  outline: none;
  color: var(--color-input-gray);
  @include responsive('font-size', px(24), px(36));
  @include responsive('line-height', px(32), px(64));
}

.tease {
  @include responsive('margin-bottom', px(24), 3rem);
}

.email-input::placeholder {
  color: var(--color-input-gray);
}

.email-input::-moz-placeholder {
  color: var(--color-input-gray);
}

.email-input::-webkit-input-placeholder {
  color: var(--color-input-gray);
}

.input-wrapper {
  @include responsive('border-bottom', 2px solid #707070, 4px solid #707070);
}

.btn-anmelden {
  font-size: px(18);
  line-height: px(25);
  @include responsive('padding', px(4) px(16), px(4) px(20));
}

.clipper {
  @apply bg-primary;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rassen-circle {
  top: -10%;
  right: 10%;

  @screen md {
    top: -20%;
  }
}

.arrow {
  height: 40px;
  :deep {
    path {
      fill: var(--color-very-dark-gray);
    }
  }
}
</style>
